/* You can add global styles to this file, and also import other style files */


html, body {
    width: 100%;
    height: 100%;
    margin: 0em;
    padding: 0em;
    overflow-x: hidden;
}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@font-face {
    font-family: Poppins;
    src: url("assets/fonts/Poppins/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins bold";
    src: url("assets/fonts/Poppins/Poppins-Bold.ttf");
}

.modal-content{
    width: 718px!important;
    min-height: 351px;
    background: #FFFFFF;
    box-shadow: inset 2px 5px 22px rgba(0, 0, 0, 0.5);
    border-radius: 25px!important;
}
.inputPrime > span {
  display: flex;
}

.p-slider .p-slider-range {
  background: #3D6E3C;
  border-radius: 5px;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.7715rem;
  margin-left: -0.5715rem;
}

.p-slider .p-slider-handle {
  /* height: 1.143rem;
  width: 1.143rem; */
  width: 28px;
  height: 28px;
  background: #EDAB05;
  border: 4px solid #3D6E3C;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-slider.p-slider-horizontal {
  /* height: 0.286rem; */
  height: 3px;
  border-radius: 5px;
}

body  {
  overflow-y: hidden;
}
